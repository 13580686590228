<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
     <a-alert
      v-if="successMessage"
      type="success"
      :message="successMessage"
      banner
      closable
    />

    <!-- filter -->
    <AForm
      layout="inline"
      ref="filterFormRef"
      :model="state.params"
      :rules="state.param_rules">
      <ARow :gutter="[8, 8]">
        <ACol
          v-if="distri !== 238 && distri !== 239 && distri !== 240 && distri !== 254 && distri !== 982"
          :xl="6" :md="8" :sm="12" :span="24">
          <AFormItem
            name="vendor_id"
            required>
            <FilterDistributor
              :disabled="(isDistributor) && (distri !== 238 && distri !== 239 && distri !== 240 && distri !== 254 && distri !== 982)"
              :mode="null"
              :allow-clear="false"
              show-search
              style="width:100%;"
              v-model:value="state.params.vendor_id"/>
        </AFormItem>
        </ACol>
        <ACol
          v-if="distri === 238 || distri === 239 || distri === 240 || distri === 254 || distri === 982"
          :xl="6" :md="8" :sm="12" :span="24">
          <AFormItem
            name="vendor_id"
            required>
            <a-select
              ref="select"
              placeholder="Pilih Distributor"
              style="width:100%;"
              v-model:value="state.params.vendor_id">
              <a-select-option :value="238">238 - SEMEN INDONESIA DISTRIBUTOR(JATIM), PT</a-select-option>
              <a-select-option :value="239">239 - SEMEN INDONESIA DISTRIBUTOR, PT (JABARDKI)</a-select-option>
              <a-select-option :value="240">240 - SEMEN INDONESIA DISTRIBUTOR (BALI), PT</a-select-option>
              <a-select-option :value="254">254 - SEMEN INDONESIA DISTRIBUTOR (JATENG-DIY), PT</a-select-option>
              <a-select-option :value="982">982 - SEMEN INDONESIA DISTRIBUTOR, PT</a-select-option>
            </a-select>
          </AFormItem>
        </ACol>
        <ACol :xl="6" :md="8" :sm="12" :span="24">
          <AFormItem
            name="date"
            required>
            <ADatePicker
              format="YYYY-MM-DD"
              format-value="YYYY-MM-DD"
              placeholder="Tanggal"
              style="width:100%;"
              v-model:value="state.params.date"/>
          </AFormItem>
        </ACol>
        <ACol :xl="6" :md="8" :sm="12" :span="24">
          <AFormItem
            name="function"
            required>
            <FilterFungsi
              style="width:100%;"
              v-model:value="state.params.function"/>
          </AFormItem>
        </ACol>
        <ACol :xl="6" :md="8" :sm="12" :span="24">
          <AFormItem
            name="type"
            required>
            <FilterJenisApi
              placeholder="Pilih Jenis API"
              :mode="null"
              :allow-clear="false"
              style="width:100%;"
              v-model:value="state.params.type"/>
          </AFormItem>
        </ACol>
        <ACol :xl="6" :md="8" :sm="12" :span="24">
          <ASpace>
            <a-button
              type="primary"
              title="cari"
              @click="fetchData"
              :loading="state.isFetching">
              <i class="fa fa-search" aria-hidden="true" v-if="!state.isFetching"></i>
              <span v-else>Memuat Data ...</span>
            </a-button>
            <a-button
              type="primary"
              stylea="width: 200px; background-color: #3acf41; border: none;"
              title="Download JSON To Xls"
              @click="fetchXlsx"
              :loading="state.isDownloading">
              <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!state.isDownloading"></i>
              <span v-else>Downloading ...</span>
            </a-button>
          </ASpace>
        </ACol>
      </ARow>
    </AForm>

    <br />
    <h5>Result API</h5>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body" style="background: #e3e3e3;">
            <pre id="resultApi"></pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  computed,
  toRefs,
} from 'vue'
import apiClient from '@/services/axios'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterJenisApi from '@/components/filter/FilterJenisApi'
import FilterFungsi from '@/components/filter/FilterFungsi'
import useUserInfo from '@/composables/useUserInfo'
import moment from 'moment'
import store from '@/store'
import { message } from 'ant-design-vue'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
  components: {
    FilterDistributor,
    FilterJenisApi,
    FilterFungsi,
  },
  setup() {
    const errorMessage = ref(null)
    const filterFormRef = ref('')
    const distri = ref(store.state.user.vendor_id)
    const { isDistributor, vendor_id } = useUserInfo()

    const state = reactive({
      endpoint: {
        current: '/api/vendor-rest-apies/request',
      },
      isFetching: false,
      isDownloading: false,
      params: extractQueryParams({
          vendor_id: [],
          type: [],
          function: [],
          date: moment(new Date()).add(-1, 'days'),
      }),
      param_rules: {
        function: [
          {
            required: true,
            message: 'Fungsi tidak boleh kosong!',
          },
        ],
        date: [
          {
            required: true,
            message: 'Tanggal tidak boleh kosong!',
          },
        ],
        type: [
          {
            required: true,
            message: 'Jenis tidak boleh kosong!',
          },
        ],
        vendor_id: [
          {
            required: true,
            message: 'Distributor tidak boleh kosong!',
          },
        ],
      },
    })

    const queryParams = () => {
      let params = state.params

      return updateUrlWithQuery(params)
    }

    const fetchData = async () => {
      await filterFormRef.value.validate()
            
      state.isFetching = true
      apiClient
        .get(state.endpoint.current, {
          params: queryParams(),
        })
        .then(response => {
          const { items, _meta, status, statusText } = response.data
          var resultApi = document.getElementById('resultApi')
          resultApi.innerHTML = JSON.stringify(response.data, undefined, 2)
          successMessage.value = `${status}`
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            var resultError = document.getElementById('resultApi')
            resultError.innerHTML = ''
            const { status, statusText } = error.response
            const message = error.response.data.message.substring(0, 150)
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const fetchXlsx = async () => {
      await filterFormRef.value.validate()

      state.isDownloading = true
      apiClient
        .get(state.endpoint.current, {
          params: { ...queryParams(), _exportall: 'xls' },
          responseType: 'blob',
          headers: {},
        })
       .then(response => {
          if (response === null) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `api-distributor_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }
    
    onMounted(() => {
      if (isDistributor.value) {
        state.params.vendor_id.push(vendor_id.value)
      }

      // set width form item (digunakan karena sangat sulit untuk mengatur lebar form item)
      const elements = document.querySelectorAll('.ant-col.ant-form-item-control-wrapper')
      elements.forEach((el) => {
        el.style.width = '100%'
      })
    })

    return {
      state,
      errorMessage,
      fetchData,
      fetchXlsx,
      filterFormRef,
      isDistributor,
      distri,
      queryParams,
    }
  },
});
</script>

<style lang="scss" scoped>
[data-vb-theme='default'] {
  .ant-row.ant-form-item,
  ::v-deep(.ant-col.ant-form-item-control-wrapper) {
    width: 100% !important;
  }
}
</style>
